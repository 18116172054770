
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import Loader from '@/components/loader.vue'; // @ is an alias to /src
import administration from '@/components/administration.vue'; // @ is an alias to /src
import administrationheader from '@/components/administrationheader.vue'; // @ is an alias to /src
import BuiltinService from '@/services/buitin-service';

@Component({
  components: {
    Header,
    Loader,
    administration,
    administrationheader
  },
})
export default class ExpatsBuiltin extends Vue {

  public fields = [{ key: 'title', sortable: true }, { key: 'actions', label: 'Actions' }];
  error_mesg = null;
  filter = null;
  // , {key: 'Action'}];
  public items = [];
  loading = true;
  private expat: any = { title: "" };

  filterOn = [];
  public currentPage = 1;
  get rows() { return this.items.length; }

  retrieve() {
    this.loading = true;
    BuiltinService.getexpats().then((response) => {
      this.items = response.data;
      this.loading = false;
    })
      .catch((e) => {
        console.log(e);
      });
  }

  handleSubmit() {
    this.create();
  }
  create() {
    this.loading = true;
    this.error_mesg = null;
    if (this.expat['id'] != null) {
      BuiltinService.putexpats(this.expat['id'], this.expat)
        .then((response) => {
          if (response) {
            this.retrieve();
            this.expat = { title: "" }
            this.loading = false;
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    } else {
      BuiltinService.postexpats(this.expat)
        .then((response) => {
          if (response) {
            this.retrieve();
            this.expat = { title: "" }
            this.loading = false;
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    }
  }
  onFiltered(filteredItems: any) { }

  delete(id: any) {
    BuiltinService.deleteexpats(id)
      .then((response) => {
        if (response) {
          this.retrieve();
          this.expat = { id: null, title: "" }
          this.loading = false;
        }
      })
      .catch((e) => {
        let eror = e.response.data;
        let r = eror[Object.keys(eror)[0]];
        this.error_mesg = r;
        this.loading = false;
      });
  }

  info(row: any) {
    this.expat['id'] = row['id'];
    this.expat['title'] = row['title'];
    this.expat['user'] = row['user'];
  }
  removeinfo(id: any) {
    this.delete(id);
  }

  mounted() {
    this.retrieve();
  }

}
